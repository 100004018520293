import React, { Component } from "react";

class ChatFeatures extends Component {
  render() {
    let {
      img1,
      img2,
      img3,
      rowClass,
      col1,
      col2,
      url,
      titleSmall,
      titlebig,
      p,
      icon,
      pClass,
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
              <div className="chat_features_img chat_features_img_one">
                <img
                  className="p_absoulte dot_bg"
                  src={require("../../img/home-chat/" + img1)}
                  alt=""
                />
                <img
                  className="chat_one"
                  data-parallax='{"x": 0, "y": 80}'
                  src={require("../../img/home-chat/" + img2)}
                  alt=""
                />
              </div>
            </div>
            <div className={col2}>
              <div id="get-app" className={`chat_features_content ${pClass}`}>
                {/* <img src={require("../../img/home-chat/" + icon)} alt="" /> */}
                <h2>
                  <span>{titleSmall}</span> {titlebig}
                </h2>
                <p>{p}</p>
                <a
                  className="class"
                  href="https://www.amazon.com/mobile-apps/b?ie=UTF8&node=2350149011"
                >
                  <img
                    src={require("../../img/amazon.png")}
                    width="70"
                    height="70"
                  />
                </a>
                <a
                  className="class"
                  href="https://testflight.apple.com/join/YuZ9kRvV"
                >
                  <img
                    src={require("../../img/AppleLogo.png")}
                    width="70"
                    height="70"
                  />
                </a>
                <a
                  className="class"
                  href="https://play.google.com/store/apps/details?id=com.northroom.bizprop"
                >
                  <img
                    src={require("../../img/PlayStore.jpeg")}
                    width="70"
                    height="70"
                  />
                </a>
                <a
                  className="class"
                  href="https://appstore.huawei.com/app/C105044891"
                >
                  <img
                    src={require("../../img/HuaweiLogo.png")}
                    width="70"
                    height="70"
                  />
                </a>
                <a className="class" href="https://galaxy.store/bizprop">
                  <img
                    src={require("../../img/samsung.png")}
                    alt="Samsung store logo"
                    width="75"
                    height="75"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures;
