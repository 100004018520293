import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/

import PaymentProcessing from "./Pages/Payment-processing";

import About from "./Pages/About";

import ScrollToTopRoute from "./ScrollToTopRoute";

import homesupport from "./Pages/home-support";



class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={homesupport} />
   
          <ScrollToTopRoute
            path="/Payment-processing"
            component={PaymentProcessing}
          />

          <ScrollToTopRoute path="/Pricing" component={About} />

  
        </Switch>
      </Router>
    );
  }
}

export default App;
