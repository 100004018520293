import React, { Component } from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import { ChatWidget } from "@papercups-io/chat-widget";
import FooterData from "../components/Footer/FooterData";
import Price from "../components/Price";
import FooterSecurity from "../components/Footer/FooterSecurity";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Alert } from "bootstrap";
import Swal from 'sweetalert2'

const style = { PaddingLeft: "100px !important" };

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      Name:"",
      Email:"",
      ContactNo:"",
      Questions:"",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose(e) {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }
  handleShow(e) {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }



  handleSubmit(e) {
    const { Name, Email, ContactNo, Questions } = this.state;
    const final = Name + "\n" + Email + "\n" + ContactNo + "\n" + Questions
    console.log(final)
    try {
      axios({
        method: "post",
        url: "https://dev.northroom.co.za/api/bizprop/message/postmessage",
        data: final,
      })
        .then(function (response) {
          //handle success
          console.log(response);
    
          Swal.fire({
            title: 'Success',
            text: 'your information has been submitted',
            icon: 'success',
            confirmButtonText: 'Okay'
          })

        })
        .catch(function (response) {
          //handle error
          console.log(response);
   
          Swal.fire({
            title: 'Error!',
            text: 'An error has occurred',
            icon: 'error',
            confirmButtonText: 'Okay'
          })
        });
    } catch (error) {
      console.error(error.response.data);
    }
    this.setState({ show: false});
  }

  render() {
    return (
      <div>
        <Modal show={this.state.show} onHide={this.state.handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                  <div className="sign_info">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="sign_info_content">
                          <h3 className="f_p f_600 f_size_24 t_color3 mb_40">
                            Purchase Info
                          </h3>
                          <h2 className="f_p f_400 f_size_30 mb-30">
                            Please enter your
                            <br /> Purchase
                            <span className="f_700"> Info</span>
                          </h2>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="login_info">
                          <h2 className="f_p f_600 f_size_24 t_color3 mb_40">
                            Details
                          </h2>
                    
                            <div className="form-group text_box">
                              <label className="f_p text_c f_400">
                                Full Name
                              </label>
                              <input
                                required
                                type="text"
                                placeholder="Full Name"
                                name="Name"
                                value={this.state.name}
                                onChange={(e)=>this.handleChange(e)}
                              />
                            </div>
                            <div className="form-group text_box">
                              <label className="f_p text_c f_400">
                                Email Address
                              </label>
                              <input
                                required
                                type="text"
                                placeholder="Bizprop@gmail.com"
                                name="Email"
                                value={this.email}
                                onChange={(e)=>this.handleChange(e)}
                              />
                            </div>
                            <div className="form-group text_box">
                              <label className="f_p text_c f_400">
                                Contact Number
                              </label>
                              <input
                                required
                                type="text"
                                placeholder="0118495725"
                                name="ContactNo"
                                value={this.contactNo}
                                onChange={(e)=>this.handleChange(e)}
                              />
                            </div>

                            <div className="form-group text_box">
                              <label className="f_p text_c f_400">
                                Questions
                              </label>
                              <textarea
                                type="text"
                                name="Questions"
                                id="Questions"
                                placeholder=""
                              rows="4"
                              value={this.Questions}
                                style={{ borderRadius: 12 }}
                                onChange={(e)=>this.handleChange(e)}
                              />
                              <label style={{ color: "red" }}>
                                {this.state.error}
                              </label>
                            </div>

                            <div className="extra mb_20">
                              <div className="checkbox remember">
                                <label>
                                  <input required type="checkbox" />
                                  <a
                                    href="https://bizprop.net/TermsandConditionsFinal.pdf"
                                    rel="noopener noreferrer"
                                target="_blank"
                                style={{paddingLeft:10}}
                                  >
                                    I agree to terms and conditions of this
                                    website
                                  </a>
                                </label>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <button
                                type="submit"
                                className="btn_get btn_hover"
                            style={{ color: "blue" }} 
                            onClick={this.handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                      
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>  
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <div className="body_wrapper">
          <CustomNavbar slogo="sticky_logo" mClass="menu_four" />
          <Breadcrumb
            breadcrumbClass="breadcrumb_area"
            Ptitle="Pricing Options"
            Pdescription=" Applicable for landlords to list their adverts.
                     "
          />

          <Price handleShow={this.handleShow} />

          <FooterSecurity FooterData={FooterData} />
          <ChatWidget
            accountId="52b6fccc-605b-4cdd-badf-22d3bcab1eb0"
            title="Welcome to Biz Prop"
            subtitle="Ask us anything in the chat window below 😊"
            primaryColor="#1890ff"
            greeting="Hi, how can we help you?"
            awayMessage=""
            newMessagePlaceholder="Start typing..."
            showAgentAvailability={true}
            agentAvailableText="We're online right now!"
            agentUnavailableText="We're away at the moment."
            requireEmailUpfront={false}
            iconVariant="outlined"
            baseUrl="https://app.papercups.io"
            // Optionally include data about your customer here to identify them
            // customer={{
            //   name: __CUSTOMER__.name,
            //   email: __CUSTOMER__.email,
            //   external_id: __CUSTOMER__.id,
            //   metadata: {
            //     plan: "premium"
            //   }
            // }}
          />
        </div>
      </div>
    );
  }
}
export default About;

/*
      <button  onClick={e => {
                    this.handleShow();
               }}
                > show Modal </button>*/

//     <Sectitle
//     sClass="hosting_title text-center"
//     Title="Please enter your details"
//     TitleP=""
//   />

//     <NetlifyForm name="Contact" action="/thanks" honeypotName="bot-field">
//     {({ handleChange, success, error }) => (
//       <>
//         <Honeypot />
//         {success && <p>Thanks for contacting us!</p>}
//         {error && (
//           <p>
//             Sorry, we could not reach our servers. Please try again
//             later.
//           </p>
//         )}
//         <div
//           style={{
//             margin: "auto",
//             width: "40%",
//             padding: "10px",
//             paddingBottom: 40,
//           }}
//         >
//           <div>
//             <label
//               style={{
//                 marginRight: "50%",
//                 display: "block",
//                 width: "50%",
//               }}
//               htmlFor="name"
//             >
//               Name :{" "}
//             </label>
//             <input
//               type="text"
//               name="name"
//               id="name"
//               style={{
//                 borderColor: "#1890ff",
//                 borderRadius: 12,
//                 width: 800,
//               }}
//               onChange={handleChange}
//             />
//           </div>
//           <div>
//             <label
//               style={{ margin: "auto", display: "block" }}
//               htmlFor="message"
//             >
//               Message:
//             </label>
//             <textarea
//               type="text"
//               name="message"
//               id="message"
//               rows="4"
//               style={{
//                 borderColor: "#1890ff",
//                 borderRadius: 12,
//                 width: 800,
//               }}
//               onChange={handleChange}
//             />
//           </div>
//           <div>
//             <button
//               style={{
//                 borderColor: "#1890ff",
//                 borderRadius: 12,
//                 margin: "auto",
//               }}
//               type="submit"
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       </>
//     )}
//   </NetlifyForm>
