import React from 'react';

const SupportBanner =()=>{
    return(
        <section  className="support_home_area">
            <div className="banner_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                        <div className="support_home_img wow fadeInUp" data-wow-delay="0.9s">
                        <img src={require ("../../img/BizLogo.png")}  style= {{"margin-left": "86px;"}} width="240px" alt=""/>
                        <p className="f_size_18 l_height30 wow fadeInUp" data-wow-delay="0.5s">Where landlords, entrepreneurs & skilled professionals meet.<br/></p>
                    </div>
                           
                    
                        </div>
                    </div>
                    <div className="support_home_img wow fadeInUp" data-wow-delay="0.9s">
                        <img src={require ("../../img/new-home/banner1.png")} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SupportBanner;