import React from 'react';
import Sectitle from '../Title/Sectitle';
import HRServiceItems from './HostingServiceitem';

const HostingService =()=> {
    return(
        <section className="hosting_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title text-center" Title="Why Choose Biz Prop" TitleP=""/>
                <div className="row">
                    <HRServiceItems  hostingicon="building8.png" hostingserviceTitle="Easily List your property" hostingservicedescription="Landlords can conveniently list their
                        commercial premises available to rent through our portal at no cost." />
                    <HRServiceItems hostingicon="building.png" hostingserviceTitle="Find your perfect space to lease" hostingservicedescription="If you are looking for a commercial
                        premises to lease, then look no further. We have a wide variety based on your trade, nationwide."/>
                    <HRServiceItems hostingicon="building2.png" hostingserviceTitle="Peace of mind" hostingservicedescription="We perform a high-level due diligence
                        on each listing prior to approving the listing to ensure that the premises is appropriate to list. We also incorporate smart AI image filtering."/>
                    <HRServiceItems hostingicon="building6.png" hostingserviceTitle="Immediate access to the landlord or
                        agent." hostingservicedescription="Details of the landlord or agent are immediately available to engage with."/>
                    <HRServiceItems hostingicon="security.png" hostingserviceTitle="World class security" hostingservicedescription="Security of user data is a key priority for us"/>
                     <HRServiceItems hostingicon="building4.png" hostingserviceTitle="Usability" hostingservicedescription="The Biz Prop App is user friendly with emphasis on maintaining user efficiency and productivity"/>
                     
                </div>
            </div>
        </section>
    )
}
export default HostingService;