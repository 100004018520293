import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
class Breadcrumb extends Component {
    render(){
        var {Ptitle, Pdescription,breadcrumbClass, imgName} = this.props;
        return(
            <section className={`${breadcrumbClass}`}>
          
                <div className="container">
                    <div className="breadcrumb_content text-center">
                        <Reveal effect="fadeInUp"><h1 className="f_p  f_size_40 b_color l_height50 mb_20">{Ptitle}</h1></Reveal>
                        <p className="f_size_18 l_height30   b_color wow fadeInUp" data-wow-delay="0.5s">{Pdescription}<br/></p>
                    </div>
                </div>
            </section> 
        )
    }
}
export default Breadcrumb;