import React, { Component } from "react";
import Sectitle from "./Title/Sectitle";

class Price extends Component {
  render() {
    return (
      <section className="support_price_area sec_pad">
        <div className="container custom_container p0">
          <div className="price_content price_content_two">
            <div className="row" style={{  justifyContent: 'center'}}>
              <div className="col-lg-8 col-sm-8" style={{alignItems: 'center',
              justifyContent: 'center',}}>
                <div className="price_item">
                  <img src={require("../img/new-home/p-3.png")} alt="" />

                  <ul className="list-unstyled p_list">
                    <li>
                      <i className="ti-check"></i>Optional app system deployment
                      to your infrastructure.
                    </li>
                    <li>
                      <i className="ti-check"></i>Free consultation to talior a
                      quote to fit your brand and your unique use case
                    </li>
                    <li>
                      <i className="ti-check"></i>Optional hosting provided by
                      us for the app and solution.
                    </li>
                    <li>
                      <i className="ti-check"></i>A once off licensing fee to
                      use the app for as long as you require.
                    </li>
                  </ul>
                  <button
                    className="price_btn btn_hover"
                                    onClick={this.props.handleShow}
                                    style={{ borderColor: "blue", color: "blue"  }}
                  >
                    Start Today
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Price;
