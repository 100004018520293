import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import SupportBanner from "../components/Banner/SupportBanner";
import Sectitle from "../components/Title/Sectitle";
import FooterData from "../components/Footer/FooterData";
import ChatFeatures from "../components/Features/ChatFeatures";
import HostingService from "../components/Service/HostingService";
import FooterSecurity from "../components/Footer/FooterSecurity";
import { ChatWidget } from "@papercups-io/chat-widget";
import { NetlifyForm, Honeypot } from "react-netlify-forms";

const homesupport = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar mClass="menu_four" />
      <SupportBanner />

      <ChatFeatures
        rowClass="flex-row-reverse"
        pClass="pr_70"
        col1="col-lg-6 offset-lg-1"
        col2="col-lg-5"
        icon="chat_icon.png"
        img1="dot.png"
        img2="tablet1.png"
        img3="chat_01.png"
        titleSmall="Biz Prop"
        titlebig="on all of your devices"
        p="Access all of the USA commercial properties available to rent across all of your devices. With Biz Prop’s simplified interface, you can find, manage and secure
            your new favorite property at the touch of a button."
        url="#"
      />

      <HostingService />

      {/* <Sectitle
        sClass="hosting_title text-center"
        Title="Please enter your details"
        TitleP=""
      />
      <NetlifyForm name="Contact" action="/thanks" honeypotName="bot-field">
        {({ handleChange, success, error }) => (
          <>
            <Honeypot />
            {success && <p>Thanks for contacting us!</p>}
            {error && (
              <p>
                Sorry, we could not reach our servers. Please try again later.
              </p>
            )}
            <div
              style={{
                margin: "auto",
                width: "40%",
                padding: "10px",
                paddingBottom: 40,
              }}
            >
              <div>
                <label
                  style={{ marginRight: "50%", display: "block", width: "50%" }}
                  htmlFor="name"
                >
                  Name :{" "}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  style={{
                    borderColor: "#1890ff",
                    borderRadius: 12,
                    width: 800,
                  }}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  style={{ margin: "auto", display: "block" }}
                  htmlFor="message"
                >
                  Message:
                </label>
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  rows="4"
                  style={{
                    borderColor: "#1890ff",
                    borderRadius: 12,
                    width: 800,
                  }}
                  onChange={handleChange}
                />
              </div>
              <div>
                <button
                  style={{
                    borderColor: "#1890ff",
                    borderRadius: 12,
                    margin: "auto",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </NetlifyForm> */}

      <FooterSecurity FooterData={FooterData} />

      <ChatWidget
        accountId="52b6fccc-605b-4cdd-badf-22d3bcab1eb0"
        title="Welcome to Biz Prop"
        subtitle="Ask us anything in the chat window below 😊"
        primaryColor="#1890ff"
        greeting="Hi, how can we help you?"
        awayMessage=""
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={true}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={false}
        iconVariant="outlined"
        baseUrl="https://app.papercups.io"
        // Optionally include data about your customer here to identify them
        // customer={{
        //   name: __CUSTOMER__.name,
        //   email: __CUSTOMER__.email,
        //   external_id: __CUSTOMER__.id,
        //   metadata: {
        //     plan: "premium"
        //   }
        // }}
      />
    </div>
  );
};
export default homesupport;
