import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";

class CustomNavbar extends Component {
  render() {
    var { mClass, nClass, cClass, slogo, hbtnClass, pagetype } = this.props;

    if (pagetype === "payment") {
      return (
        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
          <header className="header_area">
            <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
              <div className={`container ${cClass}`}>
                <Link className={`navbar-brand ${slogo}`} to="/">
                  <img
                    src={require("../img/BizLogo.png")}
                    alt="logo"
                    width="80px"
                  />
                  <img
                    src={require("../img/BizLogo.png")}
                    alt="logo"
                    width="80px"
                  />
                </Link>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="menu_toggle">
                    <span className="hamburger">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                    <span className="hamburger-cross">
                      <span></span>
                      <span></span>
                    </span>
                  </span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                    {/*  */}

                    <li className="dropdown submenu nav-item"></li>
                    <li className="dropdown submenu nav-item">
                      <Link to="./Pricing" title="Pricing" role="button">
                        Pricing
                      </Link>
                      <ul role="menu" className=" dropdown-menu"></ul>
                    </li>

                    <li className="nav-item dropdown submenu"></li>
                  </ul>

                  <Link className={`navbar-brand ${slogo}`} to="/#get-app">
                    <a className={`btn_get btn_hover ${hbtnClass}`} href="/">
                      Download our App
                    </a>
                  </Link>

                  <Link to="./Pricing" title="Pricing" role="button">
                    Purchase
                  </Link>
                </div>
              </div>
            </nav>
          </header>
        </Sticky>
      );
    }
    if (pagetype !== "payment") {
      return (
        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
          <header className="header_area">
            <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
              <div className={`container ${cClass}`}>
                <Link className={`navbar-brand ${slogo}`} to="/">
                  <img
                    src={require("../img/BizLogo.png")}
                    alt="logo"
                    width="80px"
                  />
                  <img
                    src={require("../img/BizLogo.png")}
                    alt="logo"
                    width="80px"
                  />
                </Link>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="menu_toggle">
                    <span className="hamburger">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                    <span className="hamburger-cross">
                      <span></span>
                      <span></span>
                    </span>
                  </span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                    {/*  */}

                    <li className="dropdown submenu nav-item"></li>
                    <li className="dropdown submenu nav-item">
                      <Link to="./Pricing" title="Pricing" role="button">
                        Pricing
                      </Link>
                      <ul role="menu" className=" dropdown-menu"></ul>
                    </li>
                    <li className="dropdown submenu nav-item">
                      <Link
                        to="/#get-app"
                        title="Download our App"
                        role="button"
                      >
                        Download our App
                      </Link>
                      <ul role="menu" className=" dropdown-menu"></ul>
                    </li>

                    <li className="nav-item dropdown submenu"></li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        </Sticky>
      );
    }
  }
}

export default CustomNavbar;
